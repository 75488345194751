/**
 * 유니버스 관련 처리
 */
import { _ } from 'lodash';

export const useUniverse = () => {
  const universeStore = useUniverseStore();
  const { createPanel } = useWindowStore();

  const openUniversePopup = (props) => {
    universeStore.visible = true;
    universeStore.popupProps = props;
  };

  const openUnvsPopup = () => {
    universeStore.visible = true;
  };

  const getPopupVisible = () => {
    return universeStore.visible;
  };

  const openUnvsWindow = (params) => {
    universeStore.orgCdList.value = [...params];
    const memu = {
      id: 'U10301',
      type: 'screen',
      title: '유니버스 설정',
      width: 1390,
      height: 630,
      multiYn: 'N',
    };
    createPanel(memu);
  };

  const openUnvsShrWindow = () => {
    const memu = {
      id: 'U10302',
      type: 'screen',
      title: '유니버스 공유',
      width: 600,
      height: 540,
      multiYn: 'N',
    };
    createPanel(memu);
  };

  return {
    openUniversePopup,
    openUnvsPopup,
    getPopupVisible,
    openUnvsWindow,
    openUnvsShrWindow,
  };
};
